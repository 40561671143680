import { useEffect, useState } from 'react';
//import wallets from './data/wallets.json';

import logo from './assets/logo.png';
import './styles/App.css';
import '@rainbow-me/rainbowkit/styles.css';

import { getDefaultWallets, RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig, useAccount } from 'wagmi';

import { mainnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { ConnectButton } from '@rainbow-me/rainbowkit';

window.Buffer = window.Buffer || require('buffer').Buffer;

const projectId = 'a83eddd272627abc736f80279ba0b2ee';

const { chains, provider } = configureChains( [mainnet],  [ publicProvider() ] );
const { connectors } = getDefaultWallets({ appName: 'Zodiacmasks Phase I Eligibility Checker', projectId, chains });
const wagmiClient = createClient({ autoConnect: true, connectors, provider })

function App() {

  const [phase1Mints, setPhase1Mints] = useState(null);
  const [phase2Mints, setPhase2Mints] = useState(null);

  const { address, isConnected } = useAccount();

  const year = new Date().getFullYear();

  const allowlistServer = "https://allowlist-thecryptomasksproject.dmtlabs.repl.co/";

  const plural = (count) => { if (count >= 2) return 's'; }

  const handleSearch = async (address) => {

    if (!address) return;
    try {
        // Phase 1
        var getDetails;
        getDetails = await fetch(allowlistServer + 'verify1?address=' + address);
        var readDetails = await getDetails.text();
        var details = JSON.parse(readDetails);
        setPhase1Mints(details[0]);
        // Phase 2
        getDetails = await fetch(allowlistServer + 'verify2?address=' + address);
        readDetails = await getDetails.text();
        details = JSON.parse(readDetails);
        setPhase2Mints(details[0]);
  } catch (err) { console.log(err); }
  };

  useEffect(() => {handleSearch(address) }, [address])

  return (<WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
      <div>
        <img src={logo} alt="The Cryptomasks Project" />
        <div className="connect"><ConnectButton theme={darkTheme()} chainStatus="none" /></div>
        <br /><p>.: Mint disponible sur : <a href="https://zodiacmasks.thecryptomasks.xyz" target="_blank">https://zodiacmasks.thecryptomasks.xyz</a> :.</p>
        {isConnected ? (
        <>
         {phase1Mints === null && phase2Mints === null ? (<p>Patience...</p>) : null }
         {phase1Mints === 0 && phase2Mints === 0 ? (<p>Désolé, ce wallet n'est whitelist pour aucune des phases de mint de la collection Zodiacmasks 😢<br />Essayez avec un autre ou rendez-vous en phase publique...</p>) : null }

         {phase1Mints >= 1 || phase2Mints >= 1 ? (<p>Félicitations, ce wallet est whitelist pour au moins une phase de mint de la collection Zodiacmasks !🔥</p>) : null }
         {phase1Mints >= 1 ? (<>Phase I : {phase1Mints} mint{plural(phase1Mints)} autorisé{plural(phase1Mints)}<br /></>) : null }
         {phase2Mints >= 1 ? (<>Phase II : {phase2Mints} mint{plural(phase2Mints)} autorisé{plural(phase2Mints)}</>) : null }

        </>) : (<p>Connectez votre wallet détenant votre ou vos Cryptomasks pour vérifier votre éligibilité au mint de la Phase I de la collection Zodiacmasks ! 🎭</p>) }

      </div>
      <footer>
        <p>The Cryptomasks Project ~ Zodiacmasks Phase I Eligibility Checker v1.2.7</p>
        <small>© {year} <a href="https://www.thecryptomasks.xyz">The Cryptomasks Project</a>. <span>Made with <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"></path></svg> by <a href="https://profile.rpgmax.fr" target="_blank" rel="noreferrer">RpGmAx</a></span></small>
      </footer>
    </RainbowKitProvider>
    </WagmiConfig>);
}

export default App;